import { Button, Card, Dialog } from "components/ui";
import { createContext, useContext, useState } from "react";
import { HiEye, HiTrash } from "react-icons/hi";
import { VscFilePdf } from "react-icons/vsc";

const ImagePreviewContext = createContext(null);

export function ImagePreviewProvider({ children }) {
  const [image_url, set_image_url] = useState(null);
  return (
    <ImagePreviewContext.Provider value={{ image_url, set_image_url }}>
      {children}
    </ImagePreviewContext.Provider>
  );
}

/**
 * @returns {{image_url:string, set_image_url:(image_url:string)=> void}}
 */
export function useImagePreview() {
  return useContext(ImagePreviewContext);
}

export function ImagePreview({
  value,
  onDelete,
  children,
  alt = "No images available",
}) {
  if (!Array.isArray(value)) {
    console.log("Image preview value is not an array");
    return;
  }

  if (value.length === 0)
    return (
      <p>
        <i>{alt}</i>
      </p>
    );

  const list = value.map((item) => (
    <ImageCard key={item.id} image={item}>
      <Button
        type="button"
        icon={<HiTrash />}
        onClick={() => onDelete(item.id)}
        size="sm"
        variant="twoTone"
      />
      {children}
    </ImageCard>
  ));
  return (
    <ImagePreviewProvider>
      <div className="grid grid-cols-2 gap-1">{list}</div>
      <ImagePreviewDialog />
    </ImagePreviewProvider>
  );
}

export function ImageCard({ image, children }) {
  const { set_image_url } = useImagePreview();

  function handlePreview() {
    set_image_url(image.image);
  }

  const isPdf = image.image.includes(".pdf");
  return (
    <div className="rounded p-1 border bg-white relative overflow-hidden group w-full h-full">
      <div className="group-hover:grid hidden transition-all duration-700 absolute inset-0 bg-black/20 place-items-center">
        <div className="flex items-center gap-1">
          <Button
            type="button"
            onClick={handlePreview}
            icon={<HiEye />}
            size="sm"
            variant="twoTone"
          />
          {children}
        </div>
      </div>
      {isPdf && (
        <iframe
          src={image.image}
          title="title"
          className="w-full h object-contain"
        />
      )}
      {!isPdf && <img src={image.image} alt="" />}
    </div>
  );
}

export function ImagePreviewDialog() {
  const { image_url, set_image_url } = useImagePreview();

  const isOpen = image_url !== null;
  function closeDialog() {
    set_image_url(null);
  }

  const isPdf = image_url?.includes(".pdf");
  let content = (
    <img src={image_url} alt="" className="h-full w-auto mx-auto" />
  );
  if (isPdf)
    content = (
      <iframe src={image_url} title="title" className="w-full h-full" />
    );

  return (
    <Dialog
      width={1400}
      height={800}
      contentClassName="overflow-hidden"
      isOpen={isOpen}
      onClose={closeDialog}
    >
      {content}
    </Dialog>
  );
}

export function PdfPreview({ image }) {
  const { set_image_url } = useImagePreview();
  return (
    <Button
      icon={<VscFilePdf size={50} />}
      size="lg"
      variant="plain"
      onClick={() => set_image_url(image)}
    />
  );
}
