import { Notification, toast } from "components/ui";
import useSWRMutation from "swr/mutation";

export function useDefaultMutation(key, fetchers, options) {
  return useSWRMutation(key, fetchers, {
    onSuccess({ data }) {
      if (data.status) options?.onSuccess?.(data.data);
      toast.push(
        <Notification
          title={data.message}
          type={data.status ? "success" : "danger"}
        />,
        {
          placement: "top-center",
        }
      );
    },
    onError(error) {
      options?.onSuccess?.(error);
      toast.push(
        <Notification
          title={error?.message ?? error.toString()}
          type="danger"
        />,
        { placement: "top-center" }
      );
    },
  });
}
