import { FormItem, Upload } from "components/ui";
import { Controller } from "react-hook-form";

/**
 * @typedef {Object} ComponentProps
 * @prop {import("react-hook-form").ControllerRenderProps<FieldValues, string>} ComponentProps.field
 * @prop {import("react-hook-form").ControllerFieldState} ComponentProps.fieldState
 * @prop {import("react-hook-form").UseFormStateReturn} ComponentProps.form
 */

/**
 *
 * @param {import("react-hook-form").ControllerProps & {name:string, layout:string, label:string, component: (props:ComponentProps)=> React.ReactNode, asterisk:boolean} } DefaultFormItemProp
 * @returns
 */
export function DefaultFormItem({
  label,
  component,
  asterisk,
  layout,
  ...rest
}) {
  return (
    <Controller
      {...rest}
      render={(props) => (
        <FormItem
          layout={layout}
          label={label}
          invalid={props.fieldState.invalid}
          errorMessage={props.fieldState?.error?.message}
          asterisk={asterisk}
        >
          {component(props)}
        </FormItem>
      )}
    />
  );
}

export function FileInput({ name, label, form, children, asterisk, ...rest }) {
  return (
    <DefaultFormItem
      name="file"
      label="File"
      asterisk={asterisk}
      control={form.control}
      component={(props) => (
        <div className="space-y-4">
          {children}
          <Upload
            {...rest}
            fileList={props.field.value}
            onChange={(files) => {
              form.setValue(props.field.name, files);
              form.trigger("file");
            }}
            onFileRemove={(files) => {
              form.setValue(props.field.name, files);
              form.trigger("file");
            }}
          />
        </div>
      )}
    />
  );
}
