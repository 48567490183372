import { Button, Dialog } from "components/ui";
import React, { createContext, useContext, useState } from "react";

const ToggleContext = createContext();

export const ToggleProvider = ({ children, open, onOpenChange }) => {
  const [_open, setIsOpen] = useState(false);

  const openDialog = () => onOpenChange?.(true) ?? setIsOpen(true);
  const closeDialog = () => onOpenChange?.(false) ?? setIsOpen(false);
  const isOpen = open ?? _open;
  return (
    <ToggleContext.Provider value={{ isOpen, openDialog, closeDialog }}>
      {children}
    </ToggleContext.Provider>
  );
};

// Custom hook to use the Dialog context
/**
 *
 * @returns {{isOpen:boolean, openDialog:() => void, closeDialog:() => void}}
 */
export const useToggle = () => {
  return useContext(ToggleContext);
};

export function Sheet({ children, open, onOpenChange, ...props }) {
  return (
    <ToggleProvider {...{ open, onOpenChange }}>
      <div {...props}>{children}</div>
    </ToggleProvider>
  );
}

export function SheetContent({ children, ...props }) {
  const { isOpen, closeDialog } = useToggle();
  return (
    <Dialog isOpen={isOpen} onClose={closeDialog} {...props}>
      {children}
    </Dialog>
  );
}

export function SheetTrigger({ asChild = false, children }) {
  const { openDialog } = useToggle();
  if (asChild) {
    return React.cloneElement(children, { onClick: openDialog });
  }
  return <Button onClick={openDialog}>{children}</Button>;
}
