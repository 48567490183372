import useSWR from "swr";
import { DefaultFormItem } from ".";
import { TENDER_CATEGORY } from "services/endPoints";
import { default_get } from "services/ApiService";
import { Alert, Select } from "components/ui";

export function useTenderCategory() {
  const { data, ...rest } = useSWR(TENDER_CATEGORY, default_get);
  const categories = data?.data?.data?.tender_category ?? [];
  const category_options = categories.map((category) => ({
    value: category.id,
    label: category.title,
    dev_code: category.dev_code,
  }));
  return { category_options, ...rest };
}

/**
 * @param {import("react-hook-form").ControllerProps & {label:string, layout?: string, size?:string, isMulti?:boolean }} props
 */
export function SelectTenderCategory(props) {
  const { category_options, isLoading, error } = useTenderCategory();
  if (error)
    return (
      <Alert type="danger" showIcon>
        {error?.message}
      </Alert>
    );
  const { disabled } = props;
  return (
    <DefaultFormItem
      {...props}
      component={(_props) => (
        <Select
          options={category_options}
          isLoading={isLoading}
          placeholder="Select category"
          isMulti={props.isMulti ?? false}
          size={props.size}
          {..._props.field}
          value={_props.field.value ?? null}
          isDisabled={disabled}
        />
      )}
    />
  );
}
