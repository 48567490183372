export const NEWS = "/news/admin";
export const TENDER = "/tender/admin";
export const QUERY = "/query/admin";
export const NMC_MANDATE = "/nmc-mandate/";
export const TENDER_CATEGORY = "/tender/category";
export const RESET_PASSWORD = "/user/reset-password";
export const FORGOT_PASSWORD = "/user/forgot-password";
export const DEPARTMENT = "/department";
export const DEPARTMENT_CATEGORY = "/department/category";
export const FACULTY = `/faculty`;
export const GALLERY = `/gallery`;
