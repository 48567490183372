const apiPrefix =
  process.env.REACT_APP_ENVIRONMENT === "staging"
    ? "https://stgapitrihms.aham.health/"
    : "https://api.trihms.co.in/";

// console.log({ apiPrefix });
const appConfig = {
  apiPrefix,
  authenticatedEntryPath: "/news",
  unAuthenticatedEntryPath: "/sign-in",
  tourPath: "/",
  enableMock: false,
  apiVersion: "v1",
};

export default appConfig;
