import { useNavigate, useSearchParams } from "react-router-dom";

export function useSearchQuery(name) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const closeDialog = () => navigate(-1);

  function openDialog(value, state = {}) {
    searchParams.set(name, value);
    navigate({ search: searchParams.toString() }, { state });
  }

  const key = searchParams.get(name);
  const isOpen = searchParams.has(name);

  return { query: key, isOpen, closeDialog, openDialog };
}
