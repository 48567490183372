import ApiService from "./ApiService";
import { FORGOT_PASSWORD, RESET_PASSWORD } from "./endPoints";

export async function apiSignIn(data) {
  return ApiService.fetchData({
    url: "/user/auth",
    method: "post",
    data,
  });
}

export async function apiSignUp(data) {
  return ApiService.fetchData({
    url: "/sign-up",
    method: "post",
    data,
  });
}

export async function apiSignOut(data) {
  return ApiService.fetchData({
    url: "/sign-out",
    method: "post",
    data,
  });
}

export async function apiForgotPassword(data) {
  return ApiService.fetchData({
    url: FORGOT_PASSWORD,
    method: "post",
    data,
  });
}

export async function apiResetPassword(data) {
  return ApiService.fetchData({
    url: FORGOT_PASSWORD,
    method: "put",
    data,
  });
}
