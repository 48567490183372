import { forwardRef } from "react";
import { FaRedo } from "react-icons/fa";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const icons = ReactQuill.Quill.import("ui/icons");
icons["redo"] = <FaRedo />;
icons["undo"] = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    stroke-width="2"
    stroke-linecap="round"
    stroke-linejoin="round"
    class="lucide lucide-rotate-ccw"
  >
    <path d="M3 12a9 9 0 1 0 9-9 9.75 9.75 0 0 0-6.74 2.74L3 8" />
    <path d="M3 3v5h5" />
  </svg>
);

ReactQuill.Quill.register(icons, true);

/**
 * @type {import("react").FC<import("react").PropsWithRef<import('react-quill').ReactQuillProps>>}
 */
const RichTextEditor = forwardRef((props, ref) => {
  return (
    <div className="rich-text-editor">
      <ReactQuill ref={ref} {...props} />
    </div>
  );
});

export default RichTextEditor;
