import { Button, Dialog } from "components/ui";
import { useState } from "react";
import { HiEye } from "react-icons/hi";

export function FilePreview({ file_url }) {
  const [selected_file, set_selected_file] = useState();
  const isOpen = !!selected_file;
  const closeDialog = () => set_selected_file(null);

  return (
    <>
      <div className="relative p-1 rounded border overflow-hidden group bg-white w-fit">
        <div className="absolute inset-0 bg-black/20 group-hover:grid hidden place-items-center">
          <Button
            size="sm"
            type="button"
            icon={<HiEye />}
            onClick={() => set_selected_file(file_url)}
          />
        </div>
        <iframe src={file_url} title="file" />
      </div>
      <Dialog
        overlayClassName="bg-black/10"
        width={1000}
        height={700}
        isOpen={isOpen}
        onClose={closeDialog}
      >
        <iframe src={file_url} title="file" className="w-full h-full" />
      </Dialog>
    </>
  );
}
