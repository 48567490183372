import classNames from "classnames";
import { Input } from "components/ui";
import { useEffect, useRef, useState } from "react";
import { HiSearch } from "react-icons/hi";
import { useSearchParams } from "react-router-dom";

export function SearchInput(props) {
  const setSearchParams = useSearchParams()[1];
  const [search, setSearch] = useState("");
  const searchRef = useRef();

  function updateSearchParams(value) {
    setSearchParams({ [props.name]: value, page: 1, count: 10 });
    props?.onChange?.(value);
  }

  function updateSearch(e) {
    const value = e.target.value;
    setSearch(value);
    if (searchRef.current) clearTimeout(searchRef.current);
    searchRef.current = setTimeout(() => {
      updateSearchParams(value);
    }, 500);
  }

  useEffect(() => {
    return () => {
      clearTimeout(searchRef.current);
    };
  }, []);

  return (
    <Input
      type="search"
      value={search}
      prefix={<HiSearch />}
      size="sm"
      {...props}
      onChange={updateSearch}
      className={classNames(
        props.className,
        "max-w-[200px] focus-within:max-w-[300px] transition-all duration-300"
      )}
    />
  );
}
